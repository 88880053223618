import React from "react";
import Seo from "../../components/Seo";
import { Link, Trans, useTranslation } from "gatsby-plugin-react-i18next";
import LogoPrestaShop from "../../img/logos/logo-prestashop.svg";
import { graphql } from "gatsby";

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

const ModulesPage = ({ data }) => {
  const { t } = useTranslation();

  return (
    <>
      <Seo title={t("Modules PrestaShop")} description={t("Modules officiels ComExpertise pour PrestaShop 1.6 et 1.7+.")}></Seo>

      <div className="my-5 container">
        <h1>
          <Trans>Modules PrestaShop / Drupal / Shopify</Trans>
        </h1>

        <p className="lead">
          <Trans>Modules pour les solutions e-commerce PrestaShop, Drupal Commerce &amp; Shopify, développés par ComExpertise.</Trans>
        </p>

        <hr className="my-5 w-75 mx-auto" />

        <div className="row">
          <div className="col-sm-6 col-lg-4">
            <div className="card mb-3">
              <img className="card-img-top p-3" src={LogoPrestaShop} alt={t("Logo PrestaShop")} />
              <div className="card-body">
                <h5 className="card-title">
                  <Trans>Modules PrestaShop</Trans>
                </h5>
                <p className="card-text">
                  <Trans>Modules pour PrestaShop 1.5, 1.6 &amp; 1.7.</Trans>
                </p>
                <Link to="/modules/prestashop/" className="btn btn-primary">
                  <Trans>Voir les modules</Trans>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-4"></div>
        </div>
      </div>
    </>
  );
};

export default ModulesPage;
